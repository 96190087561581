import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function DailyUpdate() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3)),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3)),
      ];

      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by the number (descending)
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/sortedItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      // Group the data by model and count occurrences
      const modelCount = sortedData.reduce((acc, item) => {
        if (acc[item.Model]) {
          acc[item.Model]++;
        } else {
          acc[item.Model] = 1;
        }
        return acc;
      }, {});

      // Convert the modelCount object into an array for easier table rendering
      const modelCountArray = Object.keys(modelCount).map((model) => ({
        model,
        count: modelCount[model],
      }));

      setData(modelCountArray); // Set the grouped data with count
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const totalPrice = () => {
    let sum = 0;

    data.forEach((item) => {
      const price = Number(item.Price) || 0;
      const com1 = Number(item.com1) || 0;
      const com2 = Number(item.com2) || 0;
      const deliveryFee = Number(item.DeliveryFee) || 0;
      sum = price + com1 + com2 + deliveryFee;

      console.log(price, com1, com2, deliveryFee);
    });
    console.log("Asdf");
    return sum;
  };
  useEffect(() => {
    fetchData();
    totalPrice();
  }, []);

  return (
    <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
      <div className="border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto justify-center align-middle">
        <h1 className="font-bold text-2xl">Phone Models</h1>
        <p className="font-bold text-xl">Model Counts</p>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left">
                Model
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left">
                Count
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="border-b-1 pb-3 border-slate-600 text-left">
                  {item.model}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left">
                  {item.count}
                </td>
              </tr>
            ))}
            <tr>
              <td className="border-t-2 pt-3 font-bold border-slate-600 text-left">
                Total
              </td>
              <td className="border-t-2 pt-3 font-bold border-slate-600 text-left">
                {data.reduce((total, item) => total + item.count, 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DailyUpdate;
