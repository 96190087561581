import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";

const apiUrl = process.env.REACT_APP_API_URL;

function DeliveryAdmin() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [editData, setEditData] = useState({
    phone: "",
    place: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      phone: item.phone,
      place: item.place,
    });
    setEditData({}); // Start with an empty object
  };

  const handleCancelClick = (item) => {
    setIsEditing(null);
    setInitialData({});
    setEditData({}); // Start with an empty object
  };

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3)),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3)),
      ];

      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by the number (descending)
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/itemDel`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  const handleSave = async (id) => {
    const completeEditData = {
      phone: editData.phone !== undefined ? editData.phone : initialData.phone,
      place: editData.place !== undefined ? editData.place : initialData.place,
    };
    try {
      setLoading(true);
      const userId = localStorage.getItem("token");

      // Send `completeEditData` as JSON data
      const response = await axios.post(
        `${apiUrl}/postDelivery/${id}`,
        completeEditData,
        {
          headers: {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Update the local state with the saved data
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData(); // Refetch data if needed
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto justify-center align-middle">
          <h1 className="font-bold text-2xl">Ethiopia</h1>
          <p className=" font-bold text-xl">Delivery</p>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Phone Number
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Place
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.ID} className=" border-b-1 border-slate-600">
                  {isEditing === item.ID ? (
                    <>
                      <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.Model}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                        <input
                          className="border"
                          type="text"
                          value={
                            editData.phone !== undefined
                              ? editData.phone
                              : item.phone
                          }
                          onChange={(e) =>
                            handleChange("phone", e.target.value)
                          }
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                        <input
                          className="border"
                          type="text"
                          value={
                            editData.place !== undefined
                              ? editData.place
                              : item.place
                          }
                          onChange={(e) =>
                            handleChange("place", e.target.value)
                          }
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 flex flex-row">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-700 w-12 mr-1 py-2 text-sm font-semibold  shadow-sm  hover:bg-gray-500 text-white"
                          onClick={() => handleSave(item.ID)}
                        >
                          save
                        </button>
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.Model}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.phone}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.place}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-700 w-12 mr-1 py-2 text-sm font-semibold  shadow-sm  hover:bg-gray-500 text-white"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default DeliveryAdmin;
