import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import noImage from "../images/errors.png";
import { Oval } from "react-loader-spinner";
import OtherProducts from "../component/OtherProducts";

const apiUrl = process.env.REACT_APP_API_URL;
function Admin() {
  const [totalPrice, setTotalPrice] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [imageFile1, setImageFile1] = useState(null); // For the fir st image
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const [totalOrder, setTotalOrder] = useState("");

  const dropdownRef = useRef(null);
  // Define short forms for months
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get current month in short form
  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [a.OrderNumber.slice(0, 3), parseInt(a.OrderNumber.slice(3), 10)];
      const [bMonth, bNumber] = [b.OrderNumber.slice(0, 3), parseInt(b.OrderNumber.slice(3), 10)];
      return aMonth !== bMonth ? bMonth.localeCompare(aMonth) : bNumber - aNumber;
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter((item) => item.OrderNumber.startsWith(month));
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    return months[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const filterByMonthAll = (month = getCurrentMonthShortForm()) => {
    setSelectedMonth(month);
    const filtered = data.filter((item) => item.OrderNumber.startsWith(month));
    setFilteredData(filtered);

    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filtered.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filtered.filter((item) => item.Status === "Sent").length;
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  // Fetch data from API and apply initial filter by month
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Filter data by current month
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month));
  };

  const updateStatus = async (itemId, ourStat) => {
    try {
      console.log("itemId ,status");
      console.log(itemId, ourStat);

      setLoading(true);
      const res = await axios.put(`${apiUrl}/updateOurStat`, {
        id: itemId,
        ourStat,
      });
      console.log(res);
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, ourStat) => {
    setDropdownOpen(null); // Close the dropdown
    // Update the status in the frontend
    setData(
      data.map((item) =>
        item.ID === itemId ? { item, ourStat: ourStat } : item
      )
    );
    // Update the status in the backend
    updateStatus(itemId, ourStat);
    // fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    brand: "",
    model: "",
    sim: "",
    ram: "",
    storage: "",
    status: "",
    delivery: "",
    price: "",
    com1: "",
    com2: "",
    rate: "",
    carrier: "",
    buyer: "",
    seller: "",
    soldPrice: "",
    receiver: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      brand: item.Brand,
      model: item.Model,
      sim: item.Sim,
      ram: item.Ram,
      storage: item.Storage,
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price, // Add image URL to initial data
      com1: item.com1, // Add image URL to initial data
      com2: item.com2, // Add image URL to initial data
      rate: item.rate, // Add image URL to initial data
      carrier: item.carrier, // Add image URL to initial data
      buyer: item.buyer, // Add image URL to initial data
      seller: item.seller, // Add image URL to initial data
      soldPrice: item.soldPrice, // Add image URL to initial data
      receiver: item.receiver, // Add image URL to initial data
    });
    setEditData({}); // Start with an empty object
    setImageFile1(null);
    setImageFile1(null); // Start with no image
  };
  const [editImage1, setEditImage1] = useState(null);
  const [editImage2, setEditImage2] = useState(null);

  // Handle changes to text fields
  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      const completeEditData = new FormData();

      // Append text fields
      completeEditData.append(
        "brand",
        editData.brand !== undefined ? editData.brand : initialData.brand
      );
      completeEditData.append(
        "model",
        editData.model !== undefined ? editData.model : initialData.model
      );
      completeEditData.append(
        "sim",
        editData.sim !== undefined ? editData.sim : initialData.sim
      );
      completeEditData.append(
        "ram",
        editData.ram !== undefined ? editData.ram : initialData.ram
      );
      completeEditData.append(
        "price",
        editData.price !== undefined ? editData.price : initialData.price
      );
      completeEditData.append(
        "storage",
        editData.storage !== undefined ? editData.storage : initialData.storage
      );
      completeEditData.append(
        "status",
        editData.status !== undefined ? editData.status : initialData.status
      );
      completeEditData.append(
        "delivery",
        editData.delivery !== undefined
          ? editData.delivery
          : initialData.delivery
      );
      completeEditData.append(
        "com1",
        editData.com1 !== undefined ? editData.com1 : initialData.com1
      );
      completeEditData.append(
        "com2",
        editData.com2 !== undefined ? editData.com2 : initialData.com2
      );
      completeEditData.append(
        "carrier",
        editData.carrier !== undefined ? editData.carrier : initialData.carrier
      );
      completeEditData.append(
        "rate",
        editData.rate !== undefined ? editData.rate : initialData.rate
      );
      completeEditData.append(
        "seller",
        editData.seller !== undefined ? editData.seller : initialData.seller
      );
      completeEditData.append(
        "buyer",
        editData.buyer !== undefined ? editData.buyer : initialData.buyer
      );
      completeEditData.append(
        "receiver",
        editData.receiver !== undefined
          ? editData.receiver
          : initialData.receiver
      );
      completeEditData.append(
        "soldPrice",
        editData.soldPrice !== undefined
          ? editData.soldPrice
          : initialData.soldPrice
      );

      // Append image files if they are selected
      if (editImage1) {
        completeEditData.append("images", editImage1); // First image
      }
      if (editImage2) {
        completeEditData.append("images", editImage2); // Second image
      }

      // Make the PUT request
      const response = await axios.put(
        `${apiUrl}/updateItem/${id}`,
        completeEditData,
        {
          headers: {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  // Update useEffect to handle filtering
  // useEffect(() => {
  //   let filtered = data;
  //   if (filterMonth) {
  //     filtered = filterByMonth(filtered, filterMonth);
  //   }
  //   if (filterDay && filterMonth) {
  //     // Only filter by day if month is selected
  //     filtered = filterByDay(filtered, filterDay, filterMonth);
  //   }
  //   if (filterStatusP) {
  //     filtered = filterStatus(filtered, filterStatusP);
  //   }
  //   setFilteredData(filtered);
  // }, [data, filterMonth, filterDay, filterStatusP]);

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const handleMonthSelect = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month)); // Update filtered data by month
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`; // Format as 'YYYY-MM-DD'
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-red-500 text-zinc-100 rounded px-5 py-2">{errors}</p>
        ) : null}
        <div className=" border-1 border-slate-200 mt-20 h-screen w-11/12 mb-20 px-5 rounded-md overflow-auto">
          <div className="flex flex-col my-2">
            <h1 className="text-3xl font-bold text-gray-800">Products</h1>
          </div>
          {isMobile ? (
            <div className="flex flex-col my-2">
              <select
                value={filterMonth}
                onChange={(e) => handleMonthSelect(e.target.value)}
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2 my-2"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!filterMonth}
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {selectedMonth &&
                  Array.from(
                    {
                      length: new Date(
                        new Date().getFullYear(),
                        months.indexOf(filterMonth) + 1,
                        0
                      ).getDate(),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-2 mr-2 mb-2"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 mb-2 rounded"
              >
                Clear
              </button>
            </div>
          ) : (
            <div className="flex flex-row my-2">
              <div className="w-3/4 mb-5">
                <label htmlFor="month-select" className="font-bold mr-2">
                  Select Month:
                </label>
                <select
                  id="month-select"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                >
                  <option value="">All</option>
                  <option value="JAN">January</option>
                  <option value="FEB">February</option>
                  <option value="MAR">March</option>
                  <option value="APR">April</option>
                  <option value="MAY">May</option>
                  <option value="JUN">June</option>
                  <option value="JUL">July</option>
                  <option value="Aug">August</option>
                  <option value="SEP">September</option>
                  <option value="Oct">October</option>
                  <option value="Nov">November</option>
                  <option value="DEC">December</option>
                </select>
              </div>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!filterMonth}
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {selectedMonth &&
                  Array.from(
                    {
                      length: new Date(
                        new Date().getFullYear(),
                        months.indexOf(filterMonth) + 1,
                        0
                      ).getDate(),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-2 mr-2"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 rounded"
              >
                Clear
              </button>
            </div>
          )}
          {/* Conditionally render the select for mobile screens */}
          <div className="flex justify-center mb-4">
            {months.map((month) => (
              <button
                key={month}
                onClick={() => filterByMonthAll(month)}
                className={`px-4 py-2 rounded-md mx-2 ${
                  selectedMonth === month
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200"
                }`}
              >
                {month}
              </button>
            ))}
            <button
              onClick={() => filterByMonthAll("")}
              className={`px-4 py-2 rounded-md mx-2 ${
                selectedMonth === "" ? "bg-blue-600 text-white" : "bg-gray-200"
              }`}
            >
              All
            </button>
          </div>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-100">
                  Brand
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Storage
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Ram
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Sim
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Price
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Delivery Fee
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Com1
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Com2
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Total ¥
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Rate
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Carrier
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Total ETB
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Images
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Buyer
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Price
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Seller
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Receiver
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Store Stat
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Date
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                const totalPriceForRow = () => {
                  const price = Number(item.Price) || 0;
                  const com1 = Number(item.com1) || 70;
                  const com2 = Number(item.com2) || 40;
                  const deliveryFee = Number(item.DeliveryFee) || 0;
                  const sum = price + com1 + com2 + deliveryFee;
                  return sum.toLocaleString();
                };
                const totalSellingPriceForRow = () => {
                  const price = Number(item.Price) || 0;
                  const com1 = Number(item.com1) || 70;
                  const com2 = Number(item.com2) || 40;
                  const deliveryFee = Number(item.DeliveryFee) || 0;
                  const rate = Number(item.rate) || 20.5;
                  const carrier = Number(item.carrier) || 1500;
                  const sum =
                    (price + com1 + com2 + deliveryFee) * rate + carrier;
                  return sum.toLocaleString();
                };
                return (
                  <tr key={item.ID}>
                    {isEditing === item.ID ? (
                      <>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.OrderNumber}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.brand !== undefined
                                ? editData.brand
                                : item.Brand
                            }
                            onChange={(e) =>
                              handleChange("brand", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.model !== undefined
                                ? editData.model
                                : item.Model
                            }
                            onChange={(e) =>
                              handleChange("model", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.storage !== undefined
                                ? editData.storage
                                : item.Storage
                            }
                            onChange={(e) =>
                              handleChange("storage", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.ram !== undefined
                                ? editData.ram
                                : item.Ram
                            }
                            onChange={(e) =>
                              handleChange("ram", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.sim !== undefined
                                ? editData.sim
                                : item.Sim
                            }
                            onChange={(e) =>
                              handleChange("sim", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.price !== undefined
                                ? editData.price
                                : item.Price
                            }
                            onChange={(e) =>
                              handleChange("price", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.delivery !== undefined
                                ? editData.delivery
                                : item.DeliveryFee
                            }
                            onChange={(e) =>
                              handleChange("delivery", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.com1 !== undefined
                                ? editData.com1
                                : item.com1
                            }
                            onChange={(e) =>
                              handleChange("com1", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.com2 !== undefined
                                ? editData.com2
                                : item.com2
                            }
                            onChange={(e) =>
                              handleChange("com2", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {totalPriceForRow()}
                        </td>

                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.rate !== undefined
                                ? editData.rate
                                : item.rate
                            }
                            onChange={(e) =>
                              handleChange("rate", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.carrier !== undefined
                                ? editData.carrier
                                : item.carrier
                            }
                            onChange={(e) =>
                              handleChange("carrier", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {totalSellingPriceForRow()}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-700 bg-gray-100">
                          {item.ImageFilename1 !== "" ||
                          item.ImageFilename2 !== "" ? (
                            <div>
                              {/* Image upload inputs */}
                              <input
                                type="file"
                                onChange={(e) =>
                                  setEditImage1(e.target.files[0])
                                }
                                accept="image/*"
                              />
                              <input
                                type="file"
                                onChange={(e) =>
                                  setEditImage2(e.target.files[0])
                                }
                                accept="image/*"
                              />
                            </div>
                          ) : (
                            <div>
                              {/* Image upload inputs */}
                              <input
                                type="file"
                                onChange={(e) =>
                                  setEditImage1(e.target.files[0])
                                }
                                accept="image/*"
                              />
                              <input
                                type="file"
                                onChange={(e) =>
                                  setEditImage2(e.target.files[0])
                                }
                                accept="image/*"
                              />
                            </div>
                          )}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            placeholder="buyer"
                            value={
                              editData.buyer !== undefined
                                ? editData.buyer
                                : item.buyer
                            }
                            onChange={(e) =>
                              handleChange("buyer", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <input
                            className="border w-full"
                            type="text"
                            placeholder="price"
                            value={
                              editData.soldPrice !== undefined
                                ? editData.soldPrice
                                : item.soldPrice
                            }
                            onChange={(e) =>
                              handleChange("soldPrice", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            placeholder="seller"
                            value={
                              editData.seller !== undefined
                                ? editData.seller
                                : item.seller
                            }
                            onChange={(e) =>
                              handleChange("seller", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <input
                            className="border w-full"
                            type="text"
                            placeholder="receiver"
                            value={
                              editData.receiver !== undefined
                                ? editData.receiver
                                : item.receiver
                            }
                            onChange={(e) =>
                              handleChange("receiver", e.target.value)
                            }
                          />
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <button
                                type="button"
                                className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                id="menu-button"
                                aria-expanded={dropdownOpen === index}
                                aria-haspopup="true"
                              >
                                {item.ourStat || "Options"}
                              </button>
                            </div>
                            {dropdownOpen === index && (
                              <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1">
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Received"
                                      )
                                    }
                                  >
                                    Received
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Sent")
                                    }
                                  >
                                    Sent
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Ren")
                                    }
                                  >
                                    Returned
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="flex flex-row border-b-1 py-3 border-slate-600 text-left m-0 bg-gray-50">
                          {loading ? (
                            <button className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              <Oval
                                height={20}
                                width={20}
                                color="#fff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            </button>
                          ) : (
                            <button
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              onClick={() => handleSave(item.ID)}
                            >
                              Save
                            </button>
                          )}
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                            onClick={() => setIsEditing(null)}
                          >
                            Cancel
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                          {item.OrderNumber}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.Brand}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.Model}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.Storage}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.Ram}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.Sim}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.Price}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.DeliveryFee}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.com1}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.com2}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {totalPriceForRow()}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.rate}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.carrier}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {totalSellingPriceForRow()}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-700 bg-gray-100">
                          {item.ImageFilename1 !== "" ||
                          item.ImageFilename2 !== "" ? (
                            <div className="flex flex-row gap-2">
                              <a href={item.imageUrl1}>
                                <img
                                  src={item.imageUrl1}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                              <a href={item.imageUrl2}>
                                <img
                                  src={item.imageUrl2}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                            </div>
                          ) : (
                            <a href="../images/no-image.png">
                              <img
                                src={noImage}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          )}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.buyer}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.soldPrice}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.seller}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          {item.receiver}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <button
                                type="button"
                                className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.ourStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              !item.ourStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                                id="menu-button"
                                aria-expanded={dropdownOpen === index}
                                aria-haspopup="true"
                                onClick={() => toggleDropdown(index)}
                              >
                                {item.ourStat || "options"}
                                <svg
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                            {dropdownOpen === index && (
                              <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1">
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Sold")
                                    }
                                  >
                                    Sold
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Received"
                                      )
                                    }
                                  >
                                    Received
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Custom"
                                      )
                                    }
                                  >
                                    Custom
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Returned"
                                      )
                                    }
                                  >
                                    Returned
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Unpaid"
                                      )
                                    }
                                  >
                                    Unpaid
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "")
                                    }
                                  >
                                    clear
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>

                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                          {formatDate(item.salesDate)}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-200 w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <OtherProducts />
      </div>
    </div>
  );
}

export default Admin;
