import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import Sidebar from "../component/Sidebar";
const apiUrl = process.env.REACT_APP_API_URL;

function Setting() {
  const [isToggled, setIsToggled] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null); // Track the open accordion
  const [products, setProducts] = useState([]);
  const [allowedColumns, setAllowedColumns] = useState([]);
  // Function to handle toggling
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  // Function to handle accordion toggle for a specific user
  const handleAccordionToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      console.log("userId", userId);
      const response = await axios.get(`${apiUrl}/user-permissions/${userId}`, {
        headers: {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("fetchedData.products", response.data.products);

      setData(response.data.products);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const userId = localStorage.getItem("token");
  useEffect(() => {
    // Fetch allowed columns and product data for the user
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user-permissions/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${userId}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setProducts(response.data.products);
        setAllowedColumns(Object.keys(response.data.products[0] || {})); // Get columns dynamically
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
         <div className="border-1 border-slate-200 mt-20 w-3/4 mb-20 p-5 rounded-md overflow-auto">
          {/*<h1 className="text-2xl mb-5">Permission</h1>
          {loading && <p>Loading...</p>}
          {errors && <p className="text-red-500">{errors}</p>}
          {!loading &&
            data.length > 0 &&
            data.map((item, index) => (
              <div
                key={item.UserId}
                className="mb-4 border border-gray-200 rounded-md"
              >
                <button
                  onClick={() => handleAccordionToggle(index)}
                  className="w-full flex justify-between items-center px-4 py-2 bg-gray-100 text-left"
                >
                  <span>{item.UserName}</span>
                  <span>{openAccordion === index ? "-" : "+"}</span>
                </button>

                {openAccordion === index && (
                  <div className="px-4 py-2 bg-white border-t border-gray-200">
                    <p>User ID: {item.ID}</p>
                    <p>Brand: {item.Brand}</p>
                    <p>Model: {item.Model}</p>
                    <p>Price: {item.Price}</p>
                  </div>
                )}
              </div>
            ))}

          <div className="flex items-center mt-5">
            <label className="relative inline-block w-12 h-6">
              <input
                type="checkbox"
                className="hidden"
                checked={isToggled}
                onChange={handleToggle}
              />
              <span
                className={`block w-full h-full rounded-full transition ${
                  isToggled ? "bg-blue-500" : "bg-gray-300"
                }`}
              ></span>
              <span
                className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full shadow-md transform transition ${
                  isToggled ? "translate-x-6" : "translate-x-0"
                }`}
              ></span>
            </label>
          </div> */}

          <div className="overflow-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  {allowedColumns.includes("UserName") && <th>Name</th>}
                  {allowedColumns.includes("Model") && <th>Price</th>}
                  {allowedColumns.includes("Brand") && <th>Stock</th>}
                  {allowedColumns.includes("Price") && (
                    <th>Description</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    {allowedColumns.includes("UserName") && <td>{product.UserName}</td>}
                    {allowedColumns.includes("Price") && (
                      <td>{product.Price}</td>
                    )}
                    {allowedColumns.includes("Model") && (
                      <td>{product.Model}</td>
                    )}
                    {allowedColumns.includes("Brand") && (
                      <td>{product.Brand}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
