import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../component/Header";
import { Oval } from "react-loader-spinner";
import Sidebar from "../component/Sidebar";
import FinanceTable from "../component/FinanceTable";

const apiUrl = process.env.REACT_APP_API_URL;

function Finance() {
  const [birr, setBirr] = useState("");
  const [yuan, setYuan] = useState("");
  const [rate, setRate] = useState("");
  const [date, setDate] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filtegreenData, setFiltegreenData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const dropdownRef = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/finance`);
      const fetchedData = response.data.map((item) => ({
        ...item,
      }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occur");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  // Update useEffect to handle filtering
  useEffect(() => {
    let filtegreen = data;
    if (filterMonth) {
      filtegreen = filterByMonth(filtegreen, filterMonth);
    }
    setFiltegreenData(filtegreen);
  }, [data, filterMonth, filterDay, filterStatusP]);

  // Calculate the number of days for the selected month

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    rate: "",
    birr: "",
    yuan: "",
    date: "",
  });

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    try {
      setLoading(true);
      const items = {
        birr: birr,
        yuan: yuan,
        rate: rate,
        date: date,
      };
      const response = await axios.put(
        `${apiUrl}/updateFinance/${id}`,
        items
      );
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occur");
    }
  };

  const updateStatus = async (itemId, status) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateDeliveryStat`, { id: itemId, status });
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occur");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePost = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const items = {
        birr: birr,
        yuan: yuan,
        rate: rate,
        date: date,
      };
      await axios.post(`${apiUrl}/postFinance`, items);

      setLoading(false);
      fetchData();
      reset();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurgreen");
    }
  };

  const reset=()=>{
    setBirr("");
    setYuan("");
    setDate("");
    setRate("");
  }

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      birr: item.birr,
      yuan: item.yuan,
      rate: item.rate,
      date: item.date,
    });
    setEditData({}); // Start with an empty object
  };

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const calculatedYuan =
      yuan && rate ? (parseFloat(yuan) * parseFloat(rate)).toFixed(2) : "";
    setBirr(calculatedYuan);
  }, [yuan, rate]);

  return (
    <div>
      <Header />
      <Sidebar/>
      <FinanceTable/>
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-green-500 text-zinc-100 rounded px-5 py-2">
            {errors}
          </p>
        ) : null}
        <div className="flex flex-col mt-10 justify-center w-3/4">
          <form onSubmit={handlePost}>
            {/* <h1 className="mb-3 font-bold text-lg">Phones</h1> */}
            <div className="grid grid-cols-4 gap-4">
              <div className="relative w-full">
                <label
                  htmlFor="yuan"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Yuan
                </label>

                <input
                  className="border p-2 w-full rounded"
                  id="yuan"
                  type="text"
                  placeholder="900"
                  value={yuan}
                  onChange={(e) => setYuan(e.target.value)}
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="rate"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Rate
                </label>
                <input
                  className="border p-2 w-full rounded"
                  id="rate"
                  type="text"
                  placeholder="20.1"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="birr"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Birr
                </label>
                <input
                  className="border p-2 w-full rounded"
                  id="birr"
                  type="text"
                  placeholder="Birr"
                  value={birr} // Yuan is calculated
                  readOnly // Make it read-only since it's auto-calculated
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="date"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Date
                </label>
                <input
                  className="border p-2 w-full rounded"
                  id="date"
                  type="date"
                  placeholder="900"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              {loading ? (
                <button className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 mt-3 rounded">
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </button>
              ) : (
                <button
                  className="bg-green-600 hover:bg-green-500 text-white font-bold py-2 px-4 mt-3 rounded"
                  type="submit"
                >
                  Add
                </button>
              )}
            </div>
          </form>
        </div>

        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-10 rounded-md overflow-auto">
          <div className="flex flex-col mt-10 mb-5 justify-between">
            <div className="flex flex-col">
              <h1 className="text-3xl font-bold text-green-800 mb-3">
                Finance
              </h1>
            </div>
            <div className="flex flex-col my-3">
              <select
                name="filterMonth"
                id="filterMonth"
                className="border p-2 mr-2 mb-2"
                value={filterMonth}
                onChange={(e) => {
                  setFilterMonth(e.target.value);
                  setFilterDay(""); // Reset day filter when month changes
                }}
              >
                <option value="" disabled>
                  Filter by Month
                </option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <button
                onClick={handleClear}
                className="bg-green-900 hover:bg-green-800 text-white font-bold px-4 rounded"
              >
                Clear
              </button>
            </div>
          </div>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-green-100">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-green-200">
                  Birr
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-100">
                  Rate
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-200">
                  Yuan
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-100">
                  date
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-200">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filtegreenData.map((item, index) => (
                <tr key={item.ID} className="border-b-1 border-slate-600">
                  {isEditing === item.ID ? (
                    <>
                      <td className=" pb-3  text-left m-0 bg-green-50"></td>
                      <td className=" pb-3  text-left m-0  bg-green-100">
                      <input
                          type="text"
                          placeholder="birr"
                          className="border border-spacing-1"
                          value={
                            editData.birr !== undefined
                              ? editData.birr
                              : item.birr
                          }
                          onChange={(e) => handleChange("birr", e.target.value)}
                        />
                      </td>
                      <td className=" pb-3  text-left m-0 bg-green-50">
                        <input
                          type="text"
                          placeholder="Rate"
                          className="border border-spacing-1"
                          value={
                            editData.rate !== undefined
                              ? editData.rate
                              : item.rate
                          }
                          onChange={(e) => handleChange("rate", e.target.value)}
                        />
                      </td>
                      <td className=" pb-3  text-left m-0 bg-green-100">
                      <input
                          type="text"
                          placeholder="Yuan"
                          className="border border-spacing-1"
                          value={
                            editData.yuan !== undefined
                              ? editData.yuan
                              : item.yuan
                          }
                          onChange={(e) => handleChange("yuan", e.target.value)}
                        /></td>
                      <td className=" pb-3  text-left m-0 bg-green-50">
                      <input
                          type="date"
                          placeholder={item.rate}
                          className="border border-spacing-1"
                          value={
                            editData.date !== undefined
                              ? editData.date
                              : item.date
                          }
                          onChange={(e) => handleChange("date", e.target.value)}
                        />
                      </td>
                      <td className="flex flex-row  py-3 text-left m-0 bg-green-100">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => handleSave(item.ID)}
                        >
                          Save
                        </button>
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-green-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-50 font-bold"></td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-100">
                        {item.birr}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-50">
                        {item.rate}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-100">
                        {item.yuan}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-50">
                        {item.date}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-100">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-green-900 hover:bg-green-800 text-white px-2 mr-1 py-2 text-sm font-semibold  shadow-sm"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Finance;
