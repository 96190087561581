import React from "react";
import EtTable from "../component/EtTable";
import CnTable from "../component/CnTable";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import SoldPhones from "../component/SoldPhones";
import Unpaid from "../component/Unpaid";
import DailyUpdate from "../component/DailyUpdate";
import FinanceTable from "../component/FinanceTable";
import People from "../component/People";

function Summary() {
  return (
    <div>
      <Header />
      <Sidebar />
      <DailyUpdate />
      <CnTable />
      <EtTable />
      <SoldPhones/>
      <Unpaid/>
      <People/>
      <FinanceTable/>
    </div>
  );
}

export default Summary;
