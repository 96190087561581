import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../component/Header";
import ReactPaginate from "react-paginate";
import noImage from "../images/errors.png";

const apiUrl = process.env.REACT_APP_API_URL;

function Shipment() {
  const [brand, setBrand] = useState("");
  const [delivery, setDelivery] = useState("");
  const [status, setStatus] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [imageFile1, setImageFile1] = useState(null); // For the first image
  const [imageFile2, setImageFile2] = useState(null); // For the second image
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [totalCommission, setTotalCommission] = useState("");
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const dropdownRef = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/shipmentItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
        imageDel1: `${apiUrl}/uploads/${item.ImageFileDel1}`, // Construct the image URL
        imageDel2: `${apiUrl}/uploads/${item.ImageFileDel2}`, // Construct the image URL
      }));
      const sum = fetchedData.length * 40;
      const receivedTotal = fetchedData.filter(
        (item) => item.DeliveryStat === "Received"
      ).length;
      const sentTotal = fetchedData.filter(
        (item) => item.DeliveryStat === "Shipped"
      ).length;
      setTotalSent(sentTotal);
      setTotalReceivedCal(receivedTotal);
      setTotalCommission(sum);
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  const filterStatus = (data, status) => {
    if (!status) return data;
    return data.filter((item) => item.Status === status); // Filter by status
  };

  // Update useEffect to handle filtering
  useEffect(() => {
    let filtered = data;
    if (filterMonth) {
      filtered = filterByMonth(filtered, filterMonth);
    }
    if (filterDay && filterMonth) {
      // Only filter by day if month is selected
      filtered = filterByDay(filtered, filterDay, filterMonth);
    }
    if (filterStatusP) {
      filtered = filterStatus(filtered, filterStatusP);
    }
    setFilteredData(filtered);
  }, [data, filterMonth, filterDay, filterStatusP]);

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Adjust this to your preference

  const startIndex = currentPage * itemsPerPage;
  const selectedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    brand: "",
    model: "",
    sim: "",
    ram: "",
    storage: "",
    status: "",
    delivery: "",
  });

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    const formData = new FormData();

    if (imageFile1) {
      formData.append("images", imageFile1);
    }
    if (imageFile2) {
      formData.append("images", imageFile2);
    }
    try {
      setLoading(true);
      console.log("the images", imageFile1, "  two ", imageFile2);
      const response = await axios.put(
        `${apiUrl}/updateItemImage/${id}`,
        formData
      );
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateStatus = async (itemId, status) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateDeliveryStat`, { id: itemId, status });
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, status) => {
    console.log("Option selected:", status);
    setDropdownOpen(null); // Close the dropdown
    // Update the status in the frontend
    setData(
      data.map((item) => (item.ID === itemId ? { item, Status: status } : item))
    );
    // Update the status in the backend
    updateStatus(itemId, status);
    fetchData();
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePost = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      const formData = new FormData();

      formData.append("status", status);
      formData.append("delivery", delivery);

      if (imageFile1) {
        formData.append("images", imageFile1);
      }
      if (imageFile2) {
        formData.append("images", imageFile2);
      }

      await axios.post(`${apiUrl}/shipPost`, formData, {
        headers: {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      brand: item.Brand,
      model: item.Model,
      sim: item.Sim,
      ram: item.Ram,
      storage: item.Storage,
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price,
    });
    setEditData({}); // Start with an empty object
  };

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-red-500 text-zinc-100 rounded px-5 py-2">{errors}</p>
        ) : null}

        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-10 rounded-md overflow-auto">
          <div className="flex flex-col mt-10 mb-5 justify-between">
            <div className="flex flex-col">
              <h1 className="text-3xl font-bold text-red-800">
                For Ship Products
              </h1>
              {/* <h6>
                {filterDay}/{filterMonth}/2024 Products
              </h6> */}
            </div>
            {isMobile ? (
              <div className="flex flex-col my-3">
                <select
                  name="filterMonth"
                  id="filterMonth"
                  className="border p-2 mr-2 mb-2"
                  value={filterMonth}
                  onChange={(e) => {
                    setFilterMonth(e.target.value);
                    setFilterDay(""); // Reset day filter when month changes
                  }}
                >
                  <option value="" disabled>
                    Filter by Month
                  </option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <select
                  name="filterDay"
                  id="filterDay"
                  className="border p-2 mr-2 mb-2"
                  value={filterDay}
                  onChange={(e) => setFilterDay(e.target.value)}
                  disabled={!filterMonth} // Disable the day filter if no month is selected
                >
                  <option value="" disabled>
                    Filter by Day
                  </option>
                  {filterMonth &&
                    Array.from(
                      { length: getDaysInMonth(filterMonth) },
                      (_, i) => i + 1
                    ).map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>

                <select
                  name="filterStatusP"
                  id="filterStatusP"
                  className="border p-2 mr-2 mb-2"
                  value={filterStatusP}
                  onChange={(e) => setFilterStatusP(e.target.value)}
                >
                  <option value="" disabled>
                    Filter by Status
                  </option>
                  <option value="Ordered">Ordered</option>
                  <option value="Received">Received</option>
                  <option value="Sent">Sent</option>
                </select>

                <button
                  onClick={handleClear}
                  className="bg-red-900 hover:bg-red-800 text-white font-bold px-4 rounded"
                >
                  Clear
                </button>
              </div>
            ) : (
              <div className="flex flex-row">
                <select
                  name="filterMonth"
                  id="filterMonth"
                  className="border p-2 mr-2"
                  value={filterMonth}
                  onChange={(e) => {
                    setFilterMonth(e.target.value);
                    setFilterDay(""); // Reset day filter when month changes
                  }}
                >
                  <option value="" disabled>
                    Filter by Month
                  </option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <select
                  name="filterDay"
                  id="filterDay"
                  className="border p-2 mr-2"
                  value={filterDay}
                  onChange={(e) => setFilterDay(e.target.value)}
                  disabled={!filterMonth} // Disable the day filter if no month is selected
                >
                  <option value="" disabled>
                    Filter by Day
                  </option>
                  {filterMonth &&
                    Array.from(
                      { length: getDaysInMonth(filterMonth) },
                      (_, i) => i + 1
                    ).map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>

                <select
                  name="filterStatusP"
                  id="filterStatusP"
                  className="border p-2 mr-2"
                  value={filterStatusP}
                  onChange={(e) => setFilterStatusP(e.target.value)}
                >
                  <option value="" disabled>
                    Filter by Status
                  </option>
                  <option value="Ordered">Ordered</option>
                  <option value="Received">Received</option>
                  <option value="Sent">Sent</option>
                </select>

                <button
                  onClick={handleClear}
                  className="bg-red-900 hover:bg-red-800 text-white font-bold px-4 rounded"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  Sent Images
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  Images
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  Status
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  Order Status
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedData.map((item, index) => (
                <tr key={item.ID} className="border-b-1 border-slate-600">
                  {isEditing === item.ID ? (
                    <>
                      <td className=" pb-3  text-left m-0">
                        {item.OrderNumber}
                      </td>
                      <td className=" pb-3  text-left m-0">{item.Model}</td>
                      <td className=" pb-3">
                        {item.ImageFilename1 !== "" ||
                        item.ImageFilename2 !== "" ? (
                          <div className="flex flex-row gap-2">
                            <a href={item.imageUrl1}>
                              <img
                                src={item.imageUrl1}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                            <a href={item.imageUrl2}>
                              <img
                                src={item.imageUrl2}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          </div>
                        ) : (
                          <a href="../images/no-image.png" className="bg-black">
                            <img
                              src={noImage}
                              alt=""
                              height="30px"
                              width="50px"
                            />
                          </a>
                        )}
                      </td>

                      <td className=" text-left m-0  w-2/6">
                        <div className="flex flex-row">
                          <div className="relative w-1/2">
                            <label
                              htmlFor="file1"
                              className="absolute left-3 font-thin text-sm bg-white text-red-400"
                              style={{ top: "-12px" }}
                            >
                              Front
                            </label>
                            <input
                              type="file"
                              id="file1"
                              accept="image/*"
                              capture="environment"
                              onChange={(e) => setImageFile1(e.target.files[0])}
                              className="text-sm w-2/3 rounded text-stone-500 border-1 file:border-0 file:text-xs file:font-medium file:bg-stone-50 file:text-stone-700 hover:file:cursor-pointer hover:file:bg-blue-50 hover:file:text-gray-900 mb-3"
                            />
                          </div>
                          <div className="relative w-1/2">
                            <label
                              htmlFor="file2"
                              className="absolute left-3 font-thin text-sm bg-white text-red-400"
                              style={{ top: "-12px" }}
                            >
                              Back
                            </label>
                            <input
                              type="file"
                              id="file2"
                              accept="image/*"
                              capture="environment"
                              onChange={(e) => setImageFile2(e.target.files[0])}
                              className="text-sm w-2/3 rounded text-stone-500 border-1  file:border-0 file:text-xs file:font-medium file:bg-stone-50 file:text-stone-700 hover:file:cursor-pointer hover:file:bg-blue-50 hover:file:text-gray-900 mb-3"
                            />
                          </div>
                        </div>
                      </td>
                      <td className=" pb-3 text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-button"
                              aria-expanded={dropdownOpen === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdown(index)}
                            >
                              {item.DeliveryStat || "Options"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpen === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-red-900 ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                            >
                              <div className="py-1">
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Ordered"
                                    )
                                  }
                                >
                                  Ordered
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Received"
                                    )
                                  }
                                >
                                  Received
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Sent")
                                  }
                                >
                                  Sent
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Returned"
                                    )
                                  }
                                >
                                  Returned
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-row  py-3 text-left m-0">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => handleSave(item.ID)}
                        >
                          Save
                        </button>
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 text-red-800 font-bold">
                        {item.OrderNumber}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                        {item.Model}
                      </td>

                      <td className="border-b-1 pb-3 border-slate-700 ">
                        {item.ImageFilename1 !== "" ||
                        item.ImageFilename2 !== "" ? (
                          <div className="flex flex-row gap-2">
                            <a href={item.imageUrl1}>
                              <img
                                src={item.imageUrl1}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                            <a href={item.imageUrl2}>
                              <img
                                src={item.imageUrl2}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          </div>
                        ) : (
                          <a
                            href="../images/no-image.png"
                            className="h-5 w-5 bg-black"
                          >
                            <img
                              src={noImage}
                              alt=""
                              height="30px"
                              width="50px"
                            />
                          </a>
                        )}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-700">
                        {item.ImageFileDel1 !== "" ||
                        item.ImageFileDel2 !== "" ? (
                          <div className="flex flex-row gap-2">
                            <a href={item.imageDel1}>
                              <img
                                src={item.imageDel1}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                            <a href={item.imageDel2}>
                              <img
                                src={item.imageDel2}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          </div>
                        ) : (
                          <a href="../images/no-image.png" className="bg-black">
                            <img
                              src={noImage}
                              alt=""
                              height="30px"
                              width="50px"
                            />
                          </a>
                        )}
                      </td>

                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-button"
                              aria-expanded={dropdownOpen === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdown(index)}
                            >
                              {item.DeliveryStat || "Options"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpen === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-red-900 ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                            >
                              <div className="py-1">
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Received"
                                    )
                                  }
                                >
                                  Received
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Shipped"
                                    )
                                  }
                                >
                                  Shipped
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Ren")
                                  }
                                >
                                  Returned
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                        {item.DeliveryStat === "Received" ? (
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-900 hover:bg-red-800 text-white px-2 mr-1 py-2 text-sm font-semibold  shadow-sm"
                            onClick={() => handleEditClick(item)}
                          >
                            Add Image
                          </button>
                        ) : (
                          <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-300 text-white px-2 mr-1 py-2 text-sm font-semibold  shadow-sm">
                            Add Image
                          </p>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination flex justify-center m-4"}
            pageClassName={"mx-1"}
            pageLinkClassName={"px-3 py-1 rounded"}
            previousClassName={"mx-1"}
            previousLinkClassName={"px-3 py-1 bg-gray-200 rounded"}
            nextClassName={"mx-1"}
            nextLinkClassName={"px-3 py-1 bg-gray-200 rounded"}
            activeClassName={"bg-red-900 text-white font-bold rounded"}
          />
        </div>

        <div className="mt-10 w-3/4 mb-20  rounded-md">
          <h1 className="text-3xl text-red-800 font-bold mb-4">Summary</h1>
          <div className=" border-1 border-slate-200  w-full mb-20 px-10 rounded-md">
            {isMobile ? (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-col">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-full p-3 text-center font-bold text-2xxl">
                  Total Received
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalReceivedCal}
                </h1>
              </div>
            ) : (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-row">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-1/3 p-3 text-center font-bold text-2xxl">
                  Total Received
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalReceivedCal}
                </h1>
              </div>
            )}
            {isMobile ? (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-col">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-full p-3 text-center font-bold text-2xxl">
                  Total Sent
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalSent}
                </h1>
              </div>
            ) : (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-row">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-1/3 p-3 text-center font-bold text-2xxl">
                  Total Sent
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalSent}
                </h1>
              </div>
            )}

            {isMobile ? (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-col align-text-bottom">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-full text-center p-3 font-bold text-2xxl">
                  Total Commission
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalCommission} ¥
                </h1>
              </div>
            ) : (
              <div className=" border-1 bg-red-200 border-slate-200 mt-9 w-1/2 mb-10 rounded-md flex flex-row align-text-bottom">
                <h1 className="bg-red-900 hover:bg-red-800 text-white rounded-md w-1/3 text-center p-3 font-bold text-2xxl">
                  Total Commission
                </h1>
                <h1 className="text-center font-bold text-2xl p-3">
                  {totalCommission} ¥
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipment;
