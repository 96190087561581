import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../component/Header";

const apiUrl = process.env.REACT_APP_API_URL;

function Delivery() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3)),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3)),
      ];

      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by the number (descending)
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/itemDel`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateStatus = async (itemId, status) => {
    console.log("Updating status:", { itemId, status }); // Log to verify data
    try {
      const userId = localStorage.getItem("token");
      setLoading(true);
      await axios.put(
        `${apiUrl}/updateDelStatus`,
        { id: itemId, status },
        {
          headers: {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, status) => {
    setDropdownOpen(null); // Close the dropdown
    // Update the status in the frontend
    setData(
      data.map((item) => (item.ID === itemId ? { item, status: status } : item))
    );
    // Update the status in the backend
    updateStatus(itemId, status);
    // fetchData();
  };

  return (
    <>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto justify-center align-middle">
          <h1 className="font-bold text-2xl">Delivery</h1>
          <p className=" font-bold text-xl"></p>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Phone Number
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Place
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  ET Status
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Receiver
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-200">
                    {item.OrderNumber}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.Model}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.phone}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.place}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.phone && item.place ? (
                      <div className="relative inline-block text-left">
                        <div className="flex flex-row justify-around">
                          <button
                            type="button"
                            className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.delStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              !item.delStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                            id="menu-button"
                            aria-expanded={dropdownOpen === index}
                            aria-haspopup="true"
                            onClick={() => toggleDropdown(index)}
                          >
                            {item.delStat || "options"}
                            <svg
                              className="-mr-1 h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                        {dropdownOpen === index && (
                          <div
                            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex="-1"
                          >
                            <div className="py-1">
                              <button
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                role="menuitem"
                                tabIndex="-1"
                                onClick={() =>
                                  handleOptionSelect(index, item.ID, "Received")
                                }
                              >
                                Received
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="relative inline-block text-left">
                        <div className="flex flex-row justify-around">
                          <button
                            type="button"
                            className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.delStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.delStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              !item.delStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                            id="menu-button"
                            aria-haspopup="true"
                          >
                            {item.delStat || "options"}
                            <svg
                              className="-mr-1 h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.UserName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row">
            <h1 className="text-2xl font-bold mr-2">Total: </h1>
            <p className="text-2xl underline"> {data.length}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delivery;
