import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function People() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataOther, setFilteredDataOther] = useState([]);

  // Get the current month in short form
  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm()); // Initialize to current month

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const parseMonthFromOrderNumber = (orderNumber) => {
    const monthPart = orderNumber.startsWith("OP")
      ? orderNumber.slice(2, 5)
      : orderNumber.slice(0, 3);
    return monthPart.toUpperCase();
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        parseMonthFromOrderNumber(a.OrderNumber),
        parseInt(a.OrderNumber.slice(-2)),
      ];
      const [bMonth, bNumber] = [
        parseMonthFromOrderNumber(b.OrderNumber),
        parseInt(b.OrderNumber.slice(-2)),
      ];
      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);
      return aMonthNum !== bMonthNum
        ? bMonthNum - aMonthNum
        : bNumber - aNumber;
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter(
      (item) => parseMonthFromOrderNumber(item.OrderNumber) === month
    );
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Initialize with current month data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const fetchDataOther = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/otherItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setDataOther(sortedData);
      setFilteredDataOther(filterDataByMonth(sortedData, selectedMonth)); // Initialize with current month data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataOther();
  }, []);

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month));
    setFilteredDataOther(filterDataByMonth(dataOther, month));
  };

  // Count items received per receiver in filteredData
  const countReceivers = (data) => {
    return data.reduce((acc, item) => {
      const receiver = item.receiver || "Unknown";
      acc[receiver] = (acc[receiver] || 0) + 1; // Count items by receiver name
      return acc;
    }, {});
  };

  const countSeller = (data) => {
    return data.reduce((acc, item) => {
      const seller = item.seller || "Unknown";
      acc[seller] = (acc[seller] || 0) + 1; // Count items by receiver name
      return acc;
    }, {});
  };

  // Calculate counts for both filteredData and filteredDataOther
  const receiverCounts = countReceivers(filteredData);
  const receiverCountsOther = countReceivers(filteredDataOther);
  
  const sellerCounts = countSeller(filteredData);
  const sellerCountsOther = countSeller(filteredDataOther);
  return (
    <div className="items-center flex flex-col align-center justify-center mt-5 overflow-auto">
      <div className=" border-1 border-blue-500 mt-20 w-3/4 mb-20 p-5 rounded-md overflow-auto">
        <div className="w-3/4 mb-5">
          <label htmlFor="month-select" className="font-bold mr-2">
            Select Month:
          </label>
          <select
            id="month-select"
            onChange={handleMonthChange}
            value={selectedMonth}
          >
            <option value="">All</option>
            <option value="JAN">January</option>
            <option value="FEB">February</option>
            <option value="MAR">March</option>
            <option value="APR">April</option>
            <option value="MAY">May</option>
            <option value="JUN">June</option>
            <option value="JUL">July</option>
            <option value="AUG">August</option>
            <option value="SEP">September</option>
            <option value="OCT">October</option>
            <option value="NOV">November</option>
            <option value="DEC">December</option>
          </select>
        </div>

        <div className="w-3/4 mb-20">
          <h1 className="font-bold text-2xl">
            Receivers - {selectedMonth || "All Months"}
          </h1>
          <p className="font-bold text-xl">Phone</p>
          <table className="table-auto w-full mb-10 text-left">
            <thead>
              <tr className="border-b-1">
                <th className="bg-blue-100">Receiver</th>
                <th className="bg-blue-200">Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(receiverCounts).map(([receiver, count]) => (
                <tr key={receiver} className="border-b-1">
                  <td className="bg-blue-50">{receiver}</td>
                  <td className="bg-blue-100">{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-3/4 mb-20">
          <h1 className="font-bold text-2xl">
            Sales - {selectedMonth || "All Months"}
          </h1>
          <p className="font-bold text-xl">Phone</p>
          <table className="table-auto w-full mb-10 text-left">
            <thead>
              <tr className="border-b-1">
                <th className="bg-blue-100">Seller</th>
                <th className="bg-blue-200">Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(sellerCounts).map(([seller, counts]) => (
                <tr key={seller} className="border-b-1">
                  <td className="bg-blue-50">{seller}</td>
                  <td className="bg-blue-100">{counts}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className="w-3/4 mb-20">
        <p className="font-bold text-xl">Other Products - {selectedMonth || "All Months"}</p>
        <table className="table-auto w-full mb-10">
          <thead>
            <tr>
              <th>Receiver</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(receiverCountsOther).map(([receiver, count]) => (
              <tr key={receiver}>
                <td>{receiver}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      </div>
    </div>
  );
}

export default People;
