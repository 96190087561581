import React, { useEffect } from "react";
import Header from "../component/Header";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const role = localStorage.getItem('role');

  useEffect(() => {
    switch (role) {
      case "China":
        navigate("/china");
        break;
      case "CustomerService":
        navigate("/customerService");
        break;
      case "Finance":
        navigate("/finance");
        break;
      case "Shipment":
        navigate("/shipment");
        break;
      case "Admin":
        navigate("/admin");
        break;
      case "Delivery":
        navigate("/delivery");
        break;
      default:
        navigate("/");
    }
  }, [role, navigate]); // Add role and navigate as dependencies

  return (
    <>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5">
        <h1>Hulu Order Management System</h1>
      </div>
    </>
  );
}

export default Home;
